<template>
  <div class="container">
    <PublicHeader :totalName="$t('history').title" />
    <div class="title" type="flex">
      <div class="item" v-for="(item, index) in titleList" :key="index">
        {{ item }}
      </div>
    </div>
    <van-pull-refresh
      v-model="refreshing"
      :pulling-text="$t('public').pullingText"
      :loosing-text="$t('public').ReleaseText"
      :loading-text="$t('public').loadingText"
      :success-text="$t('public').RefreshSucceeded"
      @refresh="onRefresh"
    >
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <div class="list" v-for="(lItem, lIndex) in list" :key="lIndex">
          <div class="item">
            {{ showProductName(lItem) }}
          </div>
          <div class="item">
            {{ lItem.Amount }}
          </div>
          <div class="item">
            {{ lItem.CreateTime }}
          </div>
          <div class="item">
            <!-- {{
              lItem.Direction === 'Rise'
                ? $t('product').Increase
                : $t('product').Decrease
            }} -->
            {{ showDirection(lItem.Direction) }}
            {{ lItem.Time }}s
          </div>
          <div
            class="item down"
            :class="{ up: lItem.Profit > 0 }"
            v-if="lItem.EndAmount !== '0.00'"
          >
            <!-- {{ lItem.Profit }}{{ $t('history').money }} -->
            {{ lItem.Profit.toFixed(2) }}
          </div>
          <div class="item" v-else>{{ $t('history').unfinished }}</div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import transactionApi from '@/api/transaction'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      titleList: [
        this.$t('history').Contract,
        this.$t('history').Amount,
        this.$t('history').Time,
        this.$t('history').Details,
        this.$t('history').Profit,
      ],
      list: [],
      form: {
        page: 1,
        size: 10,
      },
      loading: false,
      finished: false,
      refreshing: false,
    }
  },
  computed: {
    ...mapGetters(['lang', 'basicConfig']),
  },
  methods: {
    showDirection(Direction) {
      let basicConfig = this.basicConfig
      let lang = this.$store.getters.lang
      if (lang === 'zh') {
        return Direction === 'Rise'
          ? basicConfig.RiseName
          : basicConfig.FallName
      } else if (lang === 'en') {
        return Direction === 'Rise'
          ? basicConfig.RiseNameEnglish
          : basicConfig.FallNameEnglish
      } else if (lang === 'ft') {
        return Direction === 'Rise'
          ? basicConfig.RiseNameTraditional
          : basicConfig.FallNameTraditional
      } else {
        return Direction === 'Rise'
          ? basicConfig.RiseNameJapanese
          : basicConfig.FallNameJapanese
      }
    },
    showProductName(Product) {
      let lang = this.$store.getters.lang
      if (lang === 'zh') {
        return Product.ProductName
      } else if (lang === 'en') {
        return Product.EnglishName
      } else {
        return Product.TraditionalName
      }
    },
    async getList() {
      const res = await transactionApi.orderList(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }

      this.loading = false
    },
    onRefresh() {
      this.form.page = 1
      this.finished = false
      this.loading = true
      setTimeout(() => {
        this.list = []
        this.getList()
      }, 100)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 14px
  color #ebe5cd
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  text-align center
  .nav
    height 30px
    margin 15px
    border-radius 2px
    border 1px solid #FAE39C
    display flex
    color #FAE39C
    &-item
      flex 1
      color #FAE39C
      flex-center()
      border-right 1px solid #FAE39C
    &-item:last-child
      border-right none
    .active
      background #FAE39C
      color #000
  .title
    font-size 13px
    height 37px
    display flex
    .item
      flex 1
      line-height 37px
  .list-box
    height calc(100vh - 86px)
    overflow scroll
  .list
    height 44px
    font-size 12px
    color #747269
    display flex
    .item
      flex 1
      flex-center()
    .fail
      color #ff0000
    .success
      color #fae39c
</style>
